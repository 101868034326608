.quote-pdf {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.786rem 1.5rem;
    color: #82868b;

    &:hover {
        color: #82868b;
    }
}
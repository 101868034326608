.react-dataTable {
    .rdt_Table {
        min-height: 300px;
    }

    &.is-loading {
        .rdt_Table {
            min-height: unset;
        }
    }

    .icon-margin {
        margin: 0 5px;
    }

    a {
        color: #6e6b7b;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }
}

.table:not(.table-dark):not(.table-light) {

    tbody:not(.table-dark) th {
        background-color: #f3f2f7;
    }
}